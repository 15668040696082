/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect, Router } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { LinearProgress } from "@material-ui/core";
// core components
import AlertDialog from "components/help/AlertDialog.jsx";
import Topo from "components/Topo/Topo.jsx";
import Footer from "components/Footer/Footer.jsx";
import Menu from "components/Menu/Menu.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

import mainRoutes from "routes/main.jsx";
// import image from "assets/img/sidebar-2.jpg";
import logo from "assets/img/logo_cbmpe.png";
import axios from 'utils/api.jsx';
import notificationTypes from "variables/notificationsTypes.json";

const API_URL = document.procEnv.API_URL;
const basePath = document.procEnv.BASE_PATH;

class MainLayout extends React.Component {
  constructor(props) {
    super(props);
    this.defaultSnackbar = {
      open: false,
      message: "",
      icon: ""
    };
    this.defaultOpenDialog = {
      open: false,
      message: "",
      title: "",
      handleDeleteAgree: () => { },
      handleDeleteDisagree: this.closeDialog
    };
    
    let auth = localStorage.getItem('user');
    auth = auth ? JSON.parse(auth) : null;

    this.state = {
      mobileOpen: false,
      progress: false,
      error_axios: false,
      snackbar: this.defaultSnackbar,
      openDialog: this.defaultOpenDialog,
      auth
    };

    let fnSuccess = (r, tf) => {
      this.handleProgressSet(tf);
      return r;
    };
    let fnError = (e) => {
      if (e && e.response) {
        let obj = {
          color: notificationTypes.error_axios.color,
          icon: notificationTypes.error_axios.icon
        };
        if (e.response.status == 401) {
          if (e.response.data.error && e.response.data.error != 'Unauthorized') {
            obj.message = e.response.data.error;
          } else {
            obj.message = 'Apenas usuários autenticados podem acessar este sistema.';
          }
          localStorage.removeItem('user');
          localStorage.removeItem('authorization');
          this.setState({auth: null});
        } else if (e.response.status == 422) {
          obj.message = 'Houve erros na validação dos dados.<br/>';
          for (var error in e.response.data.errors) {
            obj.message += e.response.data.errors[error] + '<br/>';
          }
        } else if (e.response.status == 403) {
          if (e.response.data.message && e.response.data.message != 'Unauthorized' && e.response.data.message != 'This action is unauthorized.') {
            obj.message = e.response.data.message;
          } else {
            obj.message = 'Ação não autorizada. Consulte as funcionalidades que o seu grupo de acesso lhe permite e as políticas de alteração da entidade.';
          }
        } else if (e.response.data && e.response.data.message) {
          obj.message = e.response.data.exception + '<br/>' + e.response.data.message;
        }
        this.setNotification(obj, 'custom_error_axios');
      } else {
        this.showNotification('error_axios');
      }
      this.handleProgressSet(false);
      return e;
    };

    axios.setRequestFns((r) => fnSuccess(r, true), fnError);
    axios.setResponseFns((r) => fnSuccess(r, false), fnError);

    this.progressIndex = 0;
    this.notificationTypes = notificationTypes;
  } 
  
  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    console.log(error, info);
  }

  setDialog = (obj, name) => {
    this.notificationTypes[name] = obj;
    this.showDialog(name);
  };

  showDialog = (type, handleDeleteAgree, handleDeleteDisagree = null) => {
    if (handleDeleteDisagree === null) {
      handleDeleteDisagree = this.closeDialog;
    }
    this.state.openDialog = this.notificationTypes[type];
    this.state.openDialog.open = true;
    this.state.openDialog.handleDeleteAgree = handleDeleteAgree;
    this.state.openDialog.handleDeleteDisagree = handleDeleteDisagree;
    this.setState({ openDialog: this.state.openDialog });
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ openDialog: this.defaultOpenDialog });
      }.bind(this),
      6000
    );
  };

  closeDialog = () => {
    this.setState({ openDialog: this.defaultOpenDialog });
  };

  setNotification = (obj, name) => {
    this.notificationTypes[name] = obj;
    this.showNotification(name);
  };

  showNotification = (type, msg) => {
    this.state.snackbar = Object.assign({}, this.notificationTypes[type]);

    if (msg !== undefined) {
      this.state.snackbar.message += ' ' + msg;
    }
    this.state.snackbar.open = true;
    this.setState({ snackbar: this.state.snackbar });
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ snackbar: this.defaultSnackbar });
      }.bind(this),
      6000
    );
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  handleProgressSet = (progress) => {
    if (progress) {
      this.progressIndex++;
    } else {
      this.progressIndex--;
    }
    this.setState({ progress: (this.progressIndex > 0) });
  }

  resizeFunction = () => {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  };

  componentDidMount() {
    window.addEventListener("resize", this.resizeFunction);
  }

  scrollTop = () => {
    this.refs.mainPanel.scrollTop = 0;
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== window.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  updateAuth = () => {
    let auth = localStorage.getItem('user');
    auth = auth ? JSON.parse(auth) : null;
    this.setState({auth});
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction);
  }

  defaultPrint = () => {
    window.print();
  }

  parsePaths = (routeItem, key) => {
    if (routeItem.devel && API_URL.indexOf('localhost') == -1) {
      return null;
    }

    if (routeItem.items) {
      return routeItem.items.map(this.parsePaths);
    }

    let base = basePath + '/';
    if (routeItem.redirect) {
      return <Redirect from={base + routeItem.path} to={base + routeItem.to} key={key} />;
    }
    if (routeItem.auth) {
      return <PrivateRoute
        path={base + routeItem.path}
        key={key}
        exact={routeItem.exact}
        component={routeItem.component}
        progress={this.state.progress}
        handleProgressSet={this.handleProgressSet}
        setNotification={this.setNotification}
        showNotification={this.showNotification}
        showDialog={this.showDialog}
        setDialog={this.setDialog}
        closeDialog={this.closeDialog}
        scrollTop={this.scrollTop}
        updateAuth={this.updateAuth}
        auth={this.state.auth}
        defaultPrint={this.defaultPrint}
      />;
    }
    return <Route
      path={base + routeItem.path}
      key={key}
      exact={routeItem.exact}
      render={(props) => (
        <routeItem.component
          progress={this.state.progress}
          handleProgressSet={this.handleProgressSet}
          setNotification={this.setNotification}
          showNotification={this.showNotification}
          showDialog={this.showDialog}
          setDialog={this.setDialog}
          closeDialog={this.closeDialog}
          scrollTop={this.scrollTop}
          updateAuth={this.updateAuth}
          auth={this.state.auth}
          defaultPrint={this.defaultPrint}
          {...props}
        />
      )}
    />;
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="errorPage">
          <h1>
            Houve um erro que encerrou a aplicação.
          </h1>
          <br/>
          <br/>
          Recarregue para tentar novamente.
          <br/>
          <br/>
          Caso o erro persista, contate equipe técnica.
          <br/>
          <br/>
          <button onClick={() => document.location.reload()}>
            Recarregar
          </button>
        </div>
      );
    }
    
    const { classes, ...rest } = this.props;
    return (
      <Router history={this.props.history} className={classes.content}>
        <div className={classes.wrapper}>
          <AlertDialog
            open={Boolean(this.state.openDialog.open)}
            title={this.state.openDialog.title}
            message={this.state.openDialog.message}
            agree={this.state.openDialog.handleDeleteAgree}
            disagree={this.state.openDialog.handleDeleteDisagree} />
          <Snackbar
            place="tc"
            color={this.state.snackbar.color}
            icon={this.state.snackbar.icon}
            message={this.state.snackbar.message ? this.state.snackbar.message : ''}
            open={Boolean(this.state.snackbar.open)}
            closeNotification={() => this.setState({ snackbar: this.defaultSnackbar })}
            close
          />
          <Menu
            routes={mainRoutes}
            logoText={"SIGAE"}
            logo={logo}
            handleDrawerToggle={this.handleDrawerToggle}
            open={Boolean(this.state.mobileOpen)}
            color="purple"
            auth={this.state.auth}
            {...rest}
          />
          <div className={"mainPanel " + classes.mainPanel} ref="mainPanel">
            <Topo
              routes={mainRoutes}
              handleDrawerToggle={this.handleDrawerToggle}
              auth={this.state.auth}
              {...rest}
            />
            <div className={"mainContent " + classes.content}>
              {
                (this.state.progress)
                  ? (<LinearProgress />)
                  : null
              }
              <div className={"mainContainer " + classes.container}>
                <Switch>
                  {mainRoutes.menu.map(this.parsePaths)}
                  {mainRoutes.settings.map(this.parsePaths)}
                </Switch>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </Router>
    );
  }
}

MainLayout.propTypes = {
  classes: PropTypes.object.isRequired
};

function PrivateRoute({ component: Component, progress, handleProgressSet, setNotification, showNotification, showDialog, setDialog, closeDialog, scrollTop, updateAuth, auth, defaultPrint, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        auth !== "" && auth !== null ?
          (<Component
            progress={progress}
            handleProgressSet={handleProgressSet}
            setNotification={setNotification}
            showNotification={showNotification}
            showDialog={showDialog}
            setDialog={setDialog}
            closeDialog={closeDialog}
            scrollTop={scrollTop}
            updateAuth={updateAuth}
            auth={auth}
            defaultPrint={defaultPrint}
            {...props}
          />)
          : (
            <Redirect
              to={{
                pathname: basePath + "/login",
                state: { from: props.location }
              }}
            />
          )
      }
    />
  );
}

export default withStyles(dashboardStyle)(MainLayout);
