import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import sidebarStyle from "assets/jss/material-dashboard-react/components/sidebarStyle.jsx";
import LinksTopo from "../Topo/LinksTopo.jsx";

const basePath = document.procEnv.BASE_PATH + '/';

function activeRoute(routeName) {
  return window.location.pathname.indexOf(routeName) > -1 ? true : false;
}

const parseMenu = (classes, color, auth) => (menuItem, key) => {
  if (!menuItem.group && auth && auth.permission) {
    let formularios = Object.keys(auth.permission);
    if (formularios.indexOf(menuItem.path) < 0) {
      return null;
    }
  }

  if (menuItem.redirect || menuItem.menu === false ||
    (menuItem.auth && !(auth !== "" && auth !== null))
  ) return null;

  var listItemClasses;
  const isActiveRoute = activeRoute(menuItem.path);

  listItemClasses = classNames({
    [" " + classes[color]]: isActiveRoute
  });
  const whiteFontClasses = classNames({
    [" " + classes.whiteFont]: isActiveRoute
  });

  if (menuItem.group) {
    let list = menuItem.items.map(parseMenu(classes, color, auth)).filter(a => {return a;});
    if (!list || !list.length) return null;
    
    return (
      <div style={{ margin: '20px 0 20px 20px' }} key={key}>
        <b>{menuItem.group}</b>
        {list}
      </div>
    );
  }
  return (
      <NavLink
        to={basePath + menuItem.path}
        className={classes.item}
        activeClassName="active"
        key={key}
      >
        <ListItem button className={classes.itemLink + listItemClasses}>
          <ListItemIcon className={classes.itemIcon + whiteFontClasses}>
            {!menuItem.img 
              ? (typeof menuItem.icon === "string" ? (
                <Icon>{menuItem.icon}</Icon>
              ) : (
                  <menuItem.icon />
              ))
              : <img src={isActiveRoute ? (menuItem.img_sel ? menuItem.img_sel : menuItem.img) : menuItem.img} 
                  title={menuItem.sidebarName}
                />
            }
          </ListItemIcon>
          <ListItemText
            primary={menuItem.sidebarName}
            className={classes.itemText + whiteFontClasses}
            disableTypography={true}
          />
        </ListItem>
      </NavLink>
  );
};

const Menu = ({ ...props }) => {
  // verifies if routeName is the one active (in browser input)
  const { auth, classes, color, logo, image, logoText, routes } = props;

  var links = (
    <List className={classes.list}>
      {routes.menu.map(parseMenu(classes, color, auth))}
    </List>
  );
  var brand = (
    <div className={classes.logo} style={{ backgroundColor: '#F9F9F9', color: 'black' }}>
      <div className={classes.logoImage}>
        <img src={logo} alt="logo" className={classes.img} />
      </div>
      {logoText}
    </div>
  );
  return (
    <div>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor="right"
          open={Boolean(props.open)}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div style={{ backgroundColor: '#F9F9F9', color: 'black'}} className={classes.sidebarWrapper}>
            {links}
            <LinksTopo
              routes={props.routes.settings}
              auth={auth}
            />
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper
          }}
        >
          {brand}
          <div style={{ backgroundColor: '#F9F9F9', color: 'black' }} className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
};

Menu.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(sidebarStyle)(Menu);
