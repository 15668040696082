// @material-ui/icons
import { Dashboard, Build, List, Layers, ContactSupport } from "@material-ui/icons";

//https://serverless-stack.com/chapters/code-splitting-in-create-react-app.html
import asyncComponent from "../layouts/AsyncComponent";

import mapa_estrategico from "assets/img/mapa_estrategico.svg";
import minhas_acoes from "assets/img/minhas_acoes.svg";
import painel_acoes from "assets/img/painel_acoes.svg";
import painel_bordo from "assets/img/painel_bordo.svg";
import plano_estrategico from "assets/img/plano_estrategico.svg";
import politicas_organizacionais from "assets/img/politicas_organizacionais.svg";
import mapa_estrategico_sel from "assets/img/mapa_estrategico_sel.svg";
import minhas_acoes_sel from "assets/img/minhas_acoes_sel.svg";
import painel_acoes_sel from "assets/img/painel_acoes_sel.svg";
import painel_bordo_sel from "assets/img/painel_bordo_sel.svg";
import plano_estrategico_sel from "assets/img/plano_estrategico_sel.svg";
import politicas_organizacionais_sel from "assets/img/politicas_organizacionais_sel.svg";

const Login = asyncComponent(() => import("views/Login.jsx"));
const RecuperarSenha = asyncComponent(() => import("views/RecuperarSenha.jsx"));
const RedefinirSenha = asyncComponent(() => import("views/RedefinirSenha.jsx"));
const Ajuda = asyncComponent(() => import("views/Ajuda.jsx"));
const Complexidade = asyncComponent(() => import("views/pr/Complexidade.jsx"));
const Notificacoes = asyncComponent(() => import("views/ad/Notificacoes.jsx"));
const Graduacao = asyncComponent(() => import("views/ad/Graduacao.jsx"));
const RelatorioResponsavel = asyncComponent(() =>
  import("views/pr/RelatorioResponsavel.jsx")
);
const MapaEstrategico = asyncComponent(() =>
  import("../views/pe/MapaEstrategico.jsx")
);
const Parametro = asyncComponent(() => import("views/ad/Parametro.jsx"));
const Organizacao = asyncComponent(() => import("views/ad/Organizacao.jsx"));
const PainelBordo = asyncComponent(() => import("views/ad/PainelBordo.jsx"));
const PlanoEstrategico = asyncComponent(() =>
  import("views/pe/PlanoEstrategico.jsx")
);
const Perspectiva = asyncComponent(() => import("views/pe/Perspectiva.jsx"));
const Pessoa = asyncComponent(() => import("views/ad/Pessoa.jsx"));
const PerfilPessoa = asyncComponent(() => import("views/ad/PerfilPessoa.jsx"));
const Grupo = asyncComponent(() => import("views/ad/Grupo.jsx"));
const PoliticaOrganizacional = asyncComponent(() =>
  import("views/pe/PoliticaOrganizacional.jsx")
);
const Prioridade = asyncComponent(() => import("views/pr/Prioridade.jsx"));
const PainelAcoes = asyncComponent(() => import("views/pr/PainelAcoes.jsx"));
const MinhasAcoes = asyncComponent(() => import("views/pr/MinhasAcoes.jsx"));
const TipoProcesso = asyncComponent(() => import("views/pr/TipoProcesso.jsx"));
const PainelProcesso = asyncComponent(() =>
  import("views/pr/PainelProcesso.jsx")
);
const Samples = asyncComponent(() => import("views/samples/Samples.jsx"));

const mainRoutes = {
  menu: [
    {
      group: "Planejamento Estratégico",
      auth: true,
      items: [
        {
          img: mapa_estrategico,
          img_sel: mapa_estrategico_sel,
          path: "mapa_estrategico",
          sidebarName: "Mapa Estratégico",
          navbarName: "Mapa Estratégico",
          icon: Layers,
          component: MapaEstrategico,
          exact: true,
          auth: true
        },
        {
          img: plano_estrategico,
          img_sel: plano_estrategico_sel,
          path: "plano_estrategico",
          sidebarName: "Plano Estratégico",
          navbarName: "Plano Estratégico",
          icon: Dashboard,
          component: PlanoEstrategico,
          exact: true,
          auth: true
        },
        {
          path: "plano_estrategico/:id",
          navbarName: "Plano Estratégico",
          icon: List,
          component: PlanoEstrategico,
          menu: false,
          auth: true
        }
      ]
    },
    {
      group: "Gestão Estratégica",
      auth: true,
      items: [
        {
          img: painel_bordo,
          img_sel: painel_bordo_sel,
          path: "painel_bordo",
          sidebarName: "Painel de Bordo",
          navbarName: "Painel de Bordo",
          icon: Dashboard,
          component: PainelBordo,
          exact: true,
          auth: true
        },
        {
          img: politicas_organizacionais,
          img_sel: politicas_organizacionais_sel,
          path: "politica_organizacional",
          sidebarName: "Políticas Organizacionais",
          navbarName: "Políticas Organizacionais",
          icon: List,
          component: PoliticaOrganizacional,
          auth: true
        }
      ]
    },
    {
      group: "Gerenciamento",
      auth: true,
      items: [
        {
          img: painel_acoes,
          img_sel: painel_acoes_sel,
          path: "painel_acoes",
          sidebarName: "Painel de Ações",
          navbarName: "Painel de Ações",
          icon: Dashboard,
          component: PainelAcoes,
          exact: true,
          auth: true
        },
        {
          img: minhas_acoes,
          img_sel: minhas_acoes_sel,
          path: "minhas_acoes",
          sidebarName: "Minhas Ações",
          navbarName: "Minhas Ações",
          icon: Dashboard,
          component: MinhasAcoes,
          exact: true,
          auth: true
        }
      ]
    },
    {
      path: "login",
      navbarName: "Login",
      menu: false,
      component: Login
    }
  ],
  settings: [
    {
      path: "ajuda",
      sidebarName: "Ajuda",
      navbarName: "Ajuda",
      icon: ContactSupport,
      component: Ajuda,
      auth: true,
      menu: false
    },
    {
      path: "complexidade",
      sidebarName: "Complexidade",
      navbarName: "Complexidade",
      icon: List,
      component: Complexidade,
      auth: true
    },
    {
      path: "configuracao",
      sidebarName: "Configuração",
      navbarName: "Configuração",
      icon: List,
      component: PerfilPessoa,
      auth: true
    },
    {
      path: "exemplos",
      sidebarName: "Exemplos",
      navbarName: "Exemplos",
      icon: Build,
      component: Samples,
      auth: true,
      devel: true
    },
    {
      path: "graduacao",
      sidebarName: "Graduação",
      navbarName: "Graduação",
      icon: List,
      component: Graduacao,
      auth: true
    },
    {
      path: "grupo",
      sidebarName: "Grupo de Pessoas",
      navbarName: "Grupo de Pessoas",
      icon: List,
      component: Grupo,
      auth: true
    },
    {
      path: "notificacoes",
      navbarName: "Notificações",
      menu: false,
      component: Notificacoes,
      auth: true
    },
    {
      path: "organizacao",
      sidebarName: "Organização",
      navbarName: "Organização",
      icon: List,
      component: Organizacao,
      auth: true
    },
    {
      path: "parametro",
      sidebarName: "Parâmetro",
      navbarName: "Parâmetro",
      icon: List,
      component: Parametro,
      auth: true
    },
    {
      path: "painel_processo",
      navbarName: "Painel de Processo",
      menu: false,
      component: PainelProcesso
    },
    {
      path: "perspectiva",
      sidebarName: "Perspectiva",
      navbarName: "Perspectiva",
      icon: List,
      component: Perspectiva,
      auth: true
    },
    {
      path: "prioridade",
      sidebarName: "Prioridade",
      navbarName: "Prioridade",
      icon: List,
      component: Prioridade,
      auth: true
    },
    {
      path: "recuperarsenha",
      navbarName: "Recuperar Senha",
      menu: false,
      component: RecuperarSenha
    },
    {
      path: "redefinirsenha",
      navbarName: "Redefinir Senha",
      menu: false,
      component: RedefinirSenha
    },
    {
      path: "relatorio",
      navbarName: "Relatórios",
      menu: false,
      component: RelatorioResponsavel,
      auth: true
    },
    {
      path: "tipo_processo",
      sidebarName: "Tipo de Processo",
      navbarName: "Tipo de Processo",
      icon: List,
      component: TipoProcesso,
      auth: true
    },
    {
      path: "usuario",
      sidebarName: "Usuário",
      navbarName: "Usuário",
      icon: List,
      component: Pessoa,
      auth: true
    },
    {
      redirect: true,
      path: "",
      to: "minhas_acoes",
      navbarName: "Redirect",
      menu: false
    }
  ]
};

export default mainRoutes;
