import React from "react";
import axios from 'utils/api.jsx';
import { LinearProgress, Fab } from "@material-ui/core";

import 'assets/css/documento.css';

const testImg = /\.(gif|png|svg|jpg|jpeg)/;

class DocumentoView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            onlyImg: props.onlyImg ? props.onlyImg : false,
            loading: false,
            id: props.id,
            documento: {}
        };
    }

    componentDidMount = () => {
        if (this.state.id) {
            this.getDocumento();
        }
    }

    componentWillReceiveProps = (props) => {
        if (props.id != this.state.id) {
            this.setState({id: props.id, documento: {}});
            if (props.id) {
                this.getDocumento();
            }
        }
    }

    getDocumento = () => {
        this.setState({loading: true});
        axios.get('api/ad/documento/' + this.state.id)
            .then((res) => {
                this.setState({ loading: false, documento: res.data.success });
            }, (e) => {
                this.setState({ loading: false });
            });
    }

    render = () => {
        if (!this.state.id) {
            return null;
        }
        if (this.state.loading) {
            return <LinearProgress />
        }

        let documentoUrl = document.procEnv.API_URL 
            + '/api/file/'
            + this.state.documento.numg_documento 
            + '/'
            + this.state.documento.nome_documento;

        if (this.state.onlyImg) {
            if (!testImg.test(this.state.documento.nome_documento)) {
                return null;
            }
            return <img src={documentoUrl} className="documentoIcon"/>;
        }

        return (
            <div className="documentoViewer">
                {
                    !testImg.test(this.state.documento.nome_documento)
                    ? null
                    : (
                        <img src={documentoUrl} className="documentoIcon"/>
                    )
                }
                <a href={documentoUrl} target="_blank">
                    {this.state.documento.desc_titulo}
                </a>
                <Fab
                    title="Remover"
                    aria-label="Remover"
                    style={{
                        float: 'right', 
                        width: '20px', 
                        height: '20px',
                        fontSize: '10px',
                        minHeight: 0
                    }}
                    onClick={this.props.excluirDocumento}
                >
                    x
                </Fab>
            </div>
        );
    }
}

export default DocumentoView;