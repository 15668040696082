import React from "react";
import moment from "moment";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import {
  Person,
  ContactSupport,
  Notifications,
  Settings
} from "@material-ui/icons";
import Button from "components/CustomButtons/Button.jsx";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import { Divider, ListItemText } from "@material-ui/core";

// import axios from 'axios';
import axios from "utils/api.jsx";
import DocumentoView from "../Documento/DocumentoView";

const API_URL = document.procEnv.API_URL;
const basePath = document.procEnv.BASE_PATH;

function activeRoute(routeName) {
  return window.location.pathname.indexOf(routeName) > -1 ? true : false;
}

class LinksTopo extends React.Component {
  constructor(props) {
    super(props);

    this.timer = null;

    this.state = {
      open: false,
      openMenu: false,
      routes: [],
      auth: props.auth,
      notificacoes: [],
      totalNotificacoes: 0,
      filters: {
        order: { data_email_enviado: "desc" },
        filters: {
          numg_pessoa_destinatario: props.auth ? props.auth.numg_pessoa : null,
          isnull_data_lida: false
        }
      }
    };

    this.prepareRoutes();
  }

  componentDidMount = () => {
    this.getNotificacoes(this.props);
  };

  getNotificacoes = props => {
    if (props.auth && props.auth.numg_pessoa) {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timerAction();
      this.timer = setInterval(this.timerAction, 5 * 60 * 1000);
    }
  };

  componentWillUnmount = () => {
    if (this.timer) {
      clearInterval(this.timer);
    }
  };

  timerAction = () => {
    axios
      .post("api/filter/ad/notificacao/pessoa?eager", this.state.filters)
      .then(res => {
        this.setState({
          notificacoes: res.data.success.data,
          totalNotificacoes: res.data.success.total
        });
      });
  };

  prepareRoutes = () => {
    this.state.routes = [];
    this.props.routes.forEach(route => {
      if (route.devel && API_URL.indexOf("localhost") == -1) {
        return null;
      }

      if (
        !route.always &&
        this.state.auth &&
        this.state.auth.permission &&
        !route.devel
      ) {
        let formularios = Object.keys(this.state.auth.permission);
        if (formularios.indexOf(route.path) < 0) {
          return null;
        }
      }
      if (route.menu === false || (route.auth && !this.state.auth)) {
        return null;
      }
      this.state.routes.push(route);
    });
  };

  componentWillReceiveProps = props => {
    if (props.auth != this.state.auth) {
      this.state.auth = props.auth;
      this.state.filters.filters["numg_pessoa_destinatario"] = props.auth
        ? props.auth.numg_pessoa
        : null;
      this.prepareRoutes();
      this.getNotificacoes(props);
    }
  };

  handleToggle = name => () => {
    this.setState(state => ({
      open: false,
      openMenu: false,
      [name]: !state[name]
    }));
  };

  handleClose = name => event => {
    if (
      (this.anchorEl && this.anchorEl.contains(event.target)) ||
      (this.anchorElMenu && this.anchorElMenu.contains(event.target))
    ) {
      return;
    }

    this.setState({ [name]: false });
  };

  notificacaoLida = (ev, notificacaoPessoa) => {
    notificacaoPessoa.data_lida = moment().format();
    axios
      .post(
        "api/ad/notificacao/pessoa/" +
          notificacaoPessoa.numg_notificacao_pessoa,
        notificacaoPessoa
      )
      .then(res => {
        this.getNotificacoes(this.props);
        this.handleClose("open")(ev);
      });
  };

  parseNotificacao = classes => (notificacaoPessoa, key) => {
    let link = "";
    if (notificacaoPessoa.notificacao.numg_tarefa) {
      link = "acao=" + notificacaoPessoa.notificacao.numg_tarefa;
    } else if (notificacaoPessoa.notificacao.numg_processo) {
      link = "planoAcao=" + notificacaoPessoa.notificacao.numg_processo;
    }

    return (
      <MenuItem
        onClick={ev => this.notificacaoLida(ev, notificacaoPessoa)}
        className={classes.dropdownItem}
        key={key}
      >
        <NavLink to={basePath + "/painel_acoes?" + link} key={key}>
          {notificacaoPessoa.notificacao.desc_notificacao}
          <br />
          {!notificacaoPessoa.notificacao.tarefa
            ? null
            : "Ação: " + notificacaoPessoa.notificacao.tarefa.nome_tarefa}
          {!notificacaoPessoa.notificacao.processo
            ? null
            : "Processo: " + notificacaoPessoa.notificacao.processo.desc_titulo}
        </NavLink>
      </MenuItem>
    );
  };

  render() {
    const { classes } = this.props;
    const { open, openMenu, routes } = this.state;

    return (
      <div className="linksTopo">
        {!routes.length ? null : (
          <div className={classes.manager}>
            <NavLink
              to={basePath + "/ajuda"}
              className={classes.item}
              activeClassName="active"
              title="Ajuda"
            >
              <Button
                color={"transparent"}
                justIcon={window.innerWidth > 959}
                simple={!(window.innerWidth > 959)}
                aria-label="Ajuda"
                className={classes.buttonLink}
              >
                <ContactSupport className={classes.icons} />
                <Hidden mdUp>
                  <p className={classes.linkText}>Ajuda</p>
                </Hidden>
              </Button>
            </NavLink>
            <Button
              buttonRef={node => {
                this.anchorElMenu = node;
              }}
              color={"transparent"}
              justIcon={window.innerWidth > 959}
              simple={!(window.innerWidth > 959)}
              aria-label="Quadro"
              className={classes.buttonLink}
              onClick={this.handleToggle("openMenu")}
              style={{ fontSize: "1em" }}
              title="Configurações"
            >
              <Settings className={classes.icons} />
              <Hidden mdUp>
                <p className={classes.linkText}>Configurações</p>
              </Hidden>
            </Button>
            <Poppers
              open={Boolean(openMenu)}
              anchorEl={this.anchorElMenu}
              transition
              disablePortal
              className={
                classNames({ [classes.popperClose]: !openMenu }) +
                " " +
                classes.pooperNav
              }
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="menu-list-grow"
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom"
                  }}
                >
                  <Paper>
                    <ClickAwayListener
                      onClickAway={this.handleClose("openMenu")}
                    >
                      <MenuList role="menu">
                        {routes.map((menuItem, key) => {
                          const listItemClasses = classNames({
                            [" " + classes["purple"]]: activeRoute(
                              menuItem.path
                            )
                          });
                          const whiteFontClasses = classNames({
                            [" " + classes.whiteFont]: activeRoute(
                              menuItem.path
                            )
                          });
                          return (
                            <NavLink
                              to={basePath + "/" + menuItem.path}
                              className={classes.item}
                              activeClassName="active"
                              key={key}
                            >
                              <MenuItem
                                className={classes.itemLink + listItemClasses}
                                onClick={this.handleClose("openMenu")}
                              >
                                <ListItemIcon
                                  className={
                                    classes.itemIcon + whiteFontClasses
                                  }
                                >
                                  {typeof menuItem.icon === "string" ? (
                                    <Icon>{menuItem.icon}</Icon>
                                  ) : (
                                    <menuItem.icon />
                                  )}
                                </ListItemIcon>
                                <ListItemText
                                  primary={menuItem.sidebarName}
                                  className={
                                    classes.itemText + whiteFontClasses
                                  }
                                  disableTypography={true}
                                />
                              </MenuItem>
                            </NavLink>
                          );
                        })}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Poppers>
          </div>
        )}
        {!this.state.auth ? null : (
          <div className={classes.manager}>
            <Button
              buttonRef={node => {
                this.anchorEl = node;
              }}
              color={"transparent"}
              justIcon={window.innerWidth > 959}
              simple={!(window.innerWidth > 959)}
              aria-owns={open ? "menu-list-grow" : null}
              aria-haspopup="true"
              onClick={this.handleToggle("open")}
              className={classes.buttonLink}
              title="Notificações"
            >
              <Notifications className={classes.icons} />
              {!this.state.totalNotificacoes ? null : (
                <span
                  className={classes.notifications}
                  title={
                    this.state.totalNotificacoes + " notificações não lidas"
                  }
                >
                  {this.state.totalNotificacoes}
                </span>
              )}
              <Hidden mdUp>
                <p onClick={this.handleClick} className={classes.linkText}>
                  Notificações
                </p>
              </Hidden>
            </Button>
            <Poppers
              open={Boolean(open)}
              anchorEl={this.anchorEl}
              transition
              disablePortal
              className={
                classNames({ [classes.popperClose]: !open }) +
                " " +
                classes.pooperNav
              }
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="menu-list-grow"
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom"
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={this.handleClose("open")}>
                      <MenuList role="menu">
                        {this.state.notificacoes.map(
                          this.parseNotificacao(classes)
                        )}
                        {!this.state.totalNotificacoes ? null : <Divider />}
                        <MenuItem
                          onClick={this.handleClose("open")}
                          className={
                            classes.dropdownItem +
                            classes.itemText +
                            classNames({
                              [" " + classes["purple"]]: activeRoute(
                                basePath + "/notificacoes"
                              )
                            })
                          }
                        >
                          <NavLink
                            to={basePath + "/notificacoes"}
                            className={
                              classes.item +
                              classNames({
                                [" " + classes.whiteFont]: activeRoute(
                                  basePath + "/notificacoes"
                                )
                              })
                            }
                            activeClassName="active"
                          >
                            Todas Notificações
                          </NavLink>
                        </MenuItem>
                        <MenuItem
                          onClick={this.handleClose("open")}
                          className={
                            classes.dropdownItem +
                            classes.itemText +
                            classNames({
                              [" " + classes["purple"]]: activeRoute(
                                basePath + "/relatorio"
                              )
                            })
                          }
                        >
                          <NavLink
                            to={basePath + "/relatorio"}
                            className={
                              classes.item +
                              classNames({
                                [" " + classes.whiteFont]: activeRoute(
                                  basePath + "/relatorio"
                                )
                              })
                            }
                            activeClassName="active"
                          >
                            Relatórios
                          </NavLink>
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Poppers>
          </div>
        )}
        <NavLink
          to={basePath + "/login"}
          className={classes.item}
          activeClassName="active"
          title={
            this.props.auth && this.props.auth.nome_completo
              ? this.props.auth.nome_completo
              : null
          }
        >
          <Button
            color={"transparent"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-label="Person"
            className={classes.buttonLink}
          >
            {!this.props.auth || !this.props.auth.numg_imagem_identificacao ? (
              <Person className={classes.icons} />
            ) : (
              <DocumentoView
                onlyImg={true}
                id={this.props.auth.numg_imagem_identificacao}
              />
            )}
            <Hidden mdUp>
              <p className={classes.linkText}>Perfil</p>
            </Hidden>
          </Button>
        </NavLink>
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(LinksTopo);
